.footer {
    font-family:Garnett;
    position: fixed;
    bottom: 0;
    background-color: #020C41 !important;    
    width: 100%;
    height: 60px;
    color: #fff;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
    /* Footer height */
}


.center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}