html, body {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Garnett';
  src: url('./content/Fonts/Garnett/Garnett-Regular.eot?') format('embedded-opentype'),
    url('./content/Fonts/Garnett/Garnett-Regular.woff') format('woff'),
    url('./content/Fonts/Garnett/Garnett-Regular.woff2') format('woff2'),
    url('./content/Fonts/Garnett/Garnett-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.swal2-confirm {
  background-color: #0000E3; 
  border-color: #0000E3;     /* Blue border */
  color: white;              /* White text */
}

.swal2-confirm:hover {
  background-color: #0000CC; /* Darker blue on hover */
  border-color: #0000CC;     /* Darker blue border on hover */
}

.swal2-cancel {
  background-color: #FA0073; /* Gray background */
  border-color: #FA0073;     /* Gray border */
  color: white;           /* White text */
}

.swal2-cancel:hover {
  background-color: #e10068; /* Darker gray on hover */
  border-color: #e10068;     /* Darker gray border on hover */
}
