.wrapper {
    min-height: 90vh;
    background-size: cover;    
    background-image: url('../../content/Images/amx-max.jpg');
    font-family: "Garnett"; 
  }
  
  .form {
    border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
    min-height: 90vh;
    max-width: 450px;
    padding-top: 80px;
    @media (max-width: $mantine-breakpoint-sm) {
      max-width: 100%;
    }
  }
  
  .title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Garnett ,
      var(--mantine-font-family) !important;
  }

  .amprimary{
    background-color: #020c41 !important;
    &:hover{
      background-color: #FA0073 !important;
      color: #FFF;
    }
  }

