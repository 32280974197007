.wrapper {
    min-height: 90vh;        
    font-family: "Garnett"; 
  }
  
  .amprimary{
    background-color: #020c41 !important;
    &:hover{
      background-color: #FA0073 !important;
      color: #FFF;
    }
  }

