.header {
    height: rem(56px);
    background-color: #020C41;    
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
  }
  
  .inner {
    height: rem(56px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link {
    display: block;
    line-height: 1;
    padding: rem(8px) rem(12px);
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  }

 .aeroLogo{
    margin-left: 20px;
    padding: 15px;
    width: 150pt;
    height: auto;
    
 }