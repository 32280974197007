.main{
    font-family: "Garnett"; 
    margin-top: 1%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1%;         
}

.btnprimary{
    margin-top: 10px;
    min-width: 90%;
}

